import { Card, Statistic } from 'antd'
import { valueType } from 'antd/es/statistic/utils'
import styled from 'styled-components'
import { NO_DATA_PLACEHOLDER } from '../../../../constants/constants'

const StyledCard = styled(Card)`
  height: 90px;
  display: flex;
  align-items: center;
`

interface WorkspaceStatisticProps {
  title: string
  value?: valueType // number | string
}

const WorkspaceStatistic = ({ title, value }: WorkspaceStatisticProps) => {
  return (
    <StyledCard>
      <Statistic
        title={title}
        value={value == null ? NO_DATA_PLACEHOLDER : value}
        valueStyle={{ fontWeight: '600' }}
      />
    </StyledCard>
  )
}

export default WorkspaceStatistic
