import { t } from 'i18next'
import { useCallback, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { styled } from 'styled-components'
import { SimpleSwitch } from '../../../components'
import {
  RiskReliefDeviationStatus,
  riskReliefDeviationStatuses,
} from '../../../constants/constants'
import { useWorkspaceDetails } from '../../../hooks'

const SwitchContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`

interface DeviationStatusSwitchProps {
  disabled?: boolean
}

const DeviationStatusSwitch = ({ disabled }: DeviationStatusSwitchProps) => {
  const { workspaceId, deviationId } = useParams()
  const { selectedWorkspace, updateWorkspaceDetails } = useWorkspaceDetails({
    workspaceId,
    preventFetch: true,
  })
  const [loading, setLoading] = useState(false)

  const selectedQueryDeviation = useMemo(() => {
    if (!selectedWorkspace?.config?.riskReliefConfig?.queryDeviations) {
      return undefined
    }

    return selectedWorkspace.config.riskReliefConfig.queryDeviations.find(
      (query) => query.searchQueryId === deviationId
    )
  }, [selectedWorkspace, deviationId])

  const isOpen = useMemo(
    () =>
      selectedQueryDeviation?.deviationStatus !==
      riskReliefDeviationStatuses.CLOSED,
    [selectedQueryDeviation?.deviationStatus]
  )

  const toggleDeviationStatus = useCallback(async () => {
    if (
      !selectedQueryDeviation ||
      !selectedWorkspace?.config?.riskReliefConfig
    ) {
      return
    }

    try {
      setLoading(true)

      const newStatus: RiskReliefDeviationStatus = isOpen
        ? riskReliefDeviationStatuses.CLOSED
        : riskReliefDeviationStatuses.OPENED

      const changedAt = new Date().toISOString()

      const updatedQueryDeviations =
        selectedWorkspace.config.riskReliefConfig.queryDeviations?.map(
          (query) =>
            query.searchQueryId === deviationId
              ? {
                  ...query,
                  deviationStatus: newStatus,
                  deviationStatusChangedAt: changedAt,
                }
              : query
        ) ?? []

      await updateWorkspaceDetails(
        {
          riskReliefConfig: {
            ...selectedWorkspace.config.riskReliefConfig,
            queryDeviations: updatedQueryDeviations,
          },
        },
        t('workspaceDetailsPage.updateDeviationStatusSuccessful')
      )
    } catch (error) {
      console.error('Failed to update deviation status:', error)
    } finally {
      setLoading(false)
    }
  }, [
    selectedQueryDeviation,
    selectedWorkspace,
    deviationId,
    isOpen,
    updateWorkspaceDetails,
  ])

  if (!selectedQueryDeviation) {
    return null
  }

  return (
    <SwitchContainer>
      {t(
        `workspaceDetailsPage.deviationStatus.${isOpen ? 'opened' : 'closed'}`
      )}
      <SimpleSwitch
        checked={isOpen}
        onClick={toggleDeviationStatus}
        loading={loading}
        disabled={disabled}
      />
    </SwitchContainer>
  )
}

export default DeviationStatusSwitch
