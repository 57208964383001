import { t } from 'i18next'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { algorithmRunStatuses } from '../../../../constants/constants'
import { useWorkspaceDetails } from '../../../../hooks'
import { smartFunctionService } from '../../../../services'
import { AlgorithmRun } from '../../../../store/reducers/workspaceReducer'
import WorkspaceStatistic from './WorkspaceStatistic'
import WorkspaceStatisticsPanelContainer from './WorkspaceStatisticsPanelContainer'

interface RiskReliefDeviationStatistics {
  totalRuns?: number
  numberOfDeviations?: number
}

const RiskReliefDeviationStatisticsPanel = () => {
  const dispatch = useDispatch()
  const { deviationId } = useParams()
  const { selectedWorkspace } = useWorkspaceDetails({ preventFetch: true })
  const [statistics, setStatistics] = useState<RiskReliefDeviationStatistics>()

  const mostRecentCompleteAlgorithmRun = useMemo(() => {
    if (!selectedWorkspace?.algorithmRuns) {
      return undefined
    }
    return selectedWorkspace?.algorithmRuns
      .filter(
        (run) =>
          run.nodeStatuses?.CAPA_GENERATION === algorithmRunStatuses.COMPLETE &&
          run.completedDate !== null
      )
      .reduce((prev, current) => {
        if (prev.completedDate && current.completedDate) {
          return Date.parse(prev.completedDate) >
            Date.parse(current.completedDate)
            ? prev
            : current
        } else if (prev.completedDate) {
          return prev
        } else {
          return current
        }
      }, selectedWorkspace.algorithmRuns[0])
  }, [selectedWorkspace])

  const calculateStatistics = useCallback(
    (res: AlgorithmRun) => {
      const totalRuns = selectedWorkspace?.algorithmRuns?.length
      const numberOfDeviations =
        selectedWorkspace?.config?.riskReliefConfig?.deviations?.filter(
          (deviation) => deviation.searchQueryId === deviationId
        ).length

      return {
        totalRuns,
        numberOfDeviations,
      }
    },
    [selectedWorkspace, deviationId]
  )

  useEffect(() => {
    if (mostRecentCompleteAlgorithmRun && selectedWorkspace?.id) {
      smartFunctionService
        .getWorkspaceAlgorithmRun({
          workspaceId: selectedWorkspace?.id,
          algorithmRunId: mostRecentCompleteAlgorithmRun.id,
        })
        .then((res) => {
          const values = calculateStatistics(res)
          setStatistics(values)
        })
        .catch((error) => {
          console.error('axios fetch error', error)
        })
    }
  }, [
    mostRecentCompleteAlgorithmRun,
    selectedWorkspace,
    calculateStatistics,
    dispatch,
  ])

  return (
    <WorkspaceStatisticsPanelContainer>
      <WorkspaceStatistic
        title={t(
          'workspaceDetailsPage.workspaceOverviewInfoPanel.riskReleifDeviationStatistics.smartFunctionRuns'
        )}
        value={statistics?.totalRuns || 0}
      />
      <WorkspaceStatistic
        title={t(
          'workspaceDetailsPage.workspaceOverviewInfoPanel.riskReleifDeviationStatistics.deviationInclusions'
        )}
        value={statistics?.numberOfDeviations || 0}
      />
    </WorkspaceStatisticsPanelContainer>
  )
}

export default RiskReliefDeviationStatisticsPanel
